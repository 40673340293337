import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Grid } from "../components/grid/grid"
import { Warning } from "../components/textinfo/textinfo"
import Subfooter from "../components/footer/subfooter"

import appStyles from "../styles/app.module.scss"

import ImageAppSearch from "../components/images/imageAppSearch"
import ImageAppSaving from "../components/images/imageAppSaving"
import ImageAppParkings from "../components/images/imageAppParkings"
import ImageAppNavigation from "../components/images/imageAppNavigation"
import ImageAppParked from "../components/images/imageAppParked"


const FindPage = () => (
  <Layout>
    <SEO 
    title="La app para buscar aparcamiento al instante | Parquick"
    description="Olvídate de buscar aparcamiento en Barcelona, Parquick lo encuentra por ti."
    />

    <Container>
      <h1 class="margintop3 center">
        <span class="h1overtitle">Olvídate de buscar aparcamiento, </span>
        Parquick lo encuentra por ti
      </h1>
      <p class="body-ultralarge center">Simple. Rápido. Ahora.</p>
    </Container>

    <section>
      <Container>
          <Grid class={appStyles.stepblue + " margintop3"}>
            <div className={appStyles.contentWrap + " l-start-1 l-end-5 m-start-1 m-end-3"}>
              <div className={appStyles.content}>
                <div className={appStyles.number}>01</div>
                <h2>¿Dónde vas?</h2>
                <p>Tanto si tienes que aparcar en tu ubicación, como si te diriges a un destino en concreto.</p>
                <p>Parquick localizará los parkings con plazas disponibles más cercanos a donde vayas, al instante.</p>
              </div>
            </div>
            <div className={appStyles.iconWrap + " l-start-7 l-end-13 m-start-4 m-end-7"}>
              <i class="icon-location_24"></i>
            </div>
            <div className={appStyles.screenWrap}>
              <div className={appStyles.screen}>
                <ImageAppSearch alt="app parking cerca de mi"/>
              </div>
            </div>
          </Grid>
          
          <Grid class="margintop3">
            <div className={appStyles.contentWrap + " l-start-9 l-end-13 m-start-5 m-end-7"}>
              <div className={appStyles.content}>
                <div className={appStyles.number}>02</div>
                <h2>Compara los parkings y elige</h2>
                <p>Parquick te muestra los 3 parkings con plazas libres más cercanos a tu destino. Compara precios, distancias o servicios.</p>
                <p>Tú eliges la mejor opción.</p>
                <Warning class="margintop1"/>
              </div>
            </div>
            <div className={appStyles.iconWrap + " l-start-1 l-end-7 m-start-1 m-end-4 right"}>
              <i class="icon-parking_24"></i>
            </div>
            <div className={appStyles.screenWrap}>
              <div className={appStyles.screen}>
                <ImageAppParkings alt="app para encontrar aparcamiento"/>
              </div>
            </div>
          </Grid>
        
          <Grid class={appStyles.stepdark + " margintop3"}>
            <div className={appStyles.contentWrap + " l-start-1 l-end-5 m-start-1 m-end-3"}>
              <div className={appStyles.content}>
                <div className={appStyles.number}>03</div>
                <h2>Déjate guiar</h2>
                <p>Inicia la ruta directamente hasta el parking.</p>
                <p>Llega y aparca.</p>
                <p>Parquick también te guía andando desde el aparcamiento hasta tu destino final.</p>
              </div>
            </div>
            <div className={appStyles.iconWrap + " l-start-7 l-end-13 m-start-4 m-end-7"}>
              <i class="icon-route_24"></i>
            </div>
            <div className={appStyles.screenWrap}>
              <div className={appStyles.screen}>
                <ImageAppNavigation alt="aplicacion para encontrar aparcamiento"/>
              </div>
            </div>
          </Grid>

          <Grid class="margintop3">
            <div className={appStyles.contentWrap + " l-start-9 l-end-13 m-start-5 m-end-7"}>
              <div className={appStyles.content}>
                <div className={appStyles.number}>04</div>
                <h2>Descubre tu ahorro</h2>
                <p>¡Yeah! Cada vez que busques aparcamiento, Parquick te muestra el ahorro que has conseguido en tiempo, dinero y contaminación.</p>
              </div>
            </div>
            <div className={appStyles.iconWrap + " l-start-1 l-end-7 m-start-1 m-end-4 right"}>
              <i class="icon-saving_24"></i>
            </div>
            <div className={appStyles.screenWrap}>
              <div className={appStyles.screen}>
                <ImageAppSaving alt="app aparcamiento cerca de mi y ahorrar"/>
              </div>
            </div>
          </Grid>

          <Grid class="margintop3">
            <div className={appStyles.contentWrap + " l-start-1 l-end-5 m-start-1 m-end-3"}>
              <div className={appStyles.content}>
                <div className={appStyles.number}>05</div>
                <h2>Recuerda dónde has aparcado</h2>
                <p>Guarda el número de plaza en la que has aparcado, y la localización de tu vehículo.</p>
                <p>Parquick te guiará de vuelta hasta tu coche.</p>
              </div>
            </div>
            <div className={appStyles.iconWrap + " l-start-7 l-end-13 m-start-4 m-end-7"}>
              <i class="icon-car_24"></i>
            </div>
            <div className={appStyles.screenWrap}>
              <div className={appStyles.screen}>
                <ImageAppParked alt="app buscador de aparcamientos"/>
              </div>
            </div>
          </Grid>

      </Container>

    </section>

    <Subfooter />
  </Layout>
)

export default FindPage

import React from "react"

import textinfoStyles from "./textinfo.module.scss"

export const Warning = props => (
    <div className={textinfoStyles.warning + ' ' + [props.class]}>
        <i class="icon-info_24"></i>
        <div>
            <p>Parquick no incluye el pago del parking.</p>
        </div>
    </div>
)

export const Info = props => (
    <div className={textinfoStyles.info + ' ' + textinfoStyles[props.align] + ' ' + [props.margin]}>
        <i class={props.icon}></i>
        <div>
            {props.children}
        </div>
    </div>
)
import React from "react"
import { Link } from "gatsby"

import { Container, Grid } from "../grid/grid"

import subfooterStyles from "./subfooter.module.scss"

import BadgeAppstore from "../../static/app-store-badge.svg"
import BadgeGoogleplay from "../../static/google-play-badge.svg"

const Subfooter = () => (
    <aside className={subfooterStyles.subfooter}>
        <Container>
            <Grid>
                <div class="l-start-1 l-end-8 m-start-1 m-end-5">
                    <h2 className={subfooterStyles.title}>Únete ya a la nueva forma de buscar parking en Barcelona</h2>
                    <p class="body-ultralarge">Descárgala gratis en tu móvil.</p>
                </div>
                <div className={subfooterStyles.storesWrap + " l-start-9 l-end-13 m-start-5 m-end-7"}>
                    <Link to="/descargar/descargar-app/" id="Footer-Descargar" className={subfooterStyles.storeButton}>
                        <img src={BadgeAppstore} id="app-store" alt="Descargar Parquick en App Store"/>
                    </Link>
                    <Link to="/descargar/descargar-app/" id="Footer-Descargar" className={subfooterStyles.storeButton}>
                        <img src={BadgeGoogleplay} id="google-play" alt="Descargar Parquick en Google Play"/>
                    </Link>
                    <div className={subfooterStyles.animationWrap}>
                        <div className={subfooterStyles.ani1}></div>
                        <div className={subfooterStyles.ani2}></div>
                        <div className={subfooterStyles.ani3}></div>
                        <div className={subfooterStyles.ani4}></div>
                        <div className={subfooterStyles.ani5}></div>
                    </div>
                </div>
            </Grid>
        </Container>
    </aside>

)

export default Subfooter